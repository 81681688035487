<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In a hypothetical experiment a student uses
        <number-value :value="massMg" unit="\text{g}" />
        and
        <stemble-latex content="$20.00\,\text{mL}$" />
        of
        <stemble-latex content="$1.00\,\text{M HCl}$" />
        acid to generate hydrogen gas.
      </p>

      <p class="mb-2">
        a) How many moles of
        <chemical-latex content="Mg" />
        were used in the experiment?
      </p>

      <calculation-input
        v-model="inputs.molMg"
        prepend-text="$\text{Mg:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        b) How many moles of
        <chemical-latex content="HCl" />
        were used in the experiment?
      </p>

      <calculation-input
        v-model="inputs.molHCl"
        prepend-text="$\text{HCl:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-n3">
        c) Which reagent will be the limiting reagent? Assume room temperature and 1 atm conditions.
      </p>

      <v-radio-group v-model="inputs.limitingReagent" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'OleMiss116PRL2_Q2part3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molMg: null,
        molHCl: null,
        limitingReagent: null,
      },
      options: [
        {
          text: 'Magnesium',
          value: 'mg',
        },
        {
          text: 'Hydrochloric acid (HCl)',
          value: 'hcl',
        },
        {
          text: 'Neither reagent was limiting',
          value: 'neither',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
  },
};
</script>
